exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-code-highlight-description-mdx": () => import("./../../../src/pages/code-highlight-description.mdx" /* webpackChunkName: "component---src-pages-code-highlight-description-mdx" */),
  "component---src-pages-code-highlight-index-js": () => import("./../../../src/pages/code-highlight/index.js" /* webpackChunkName: "component---src-pages-code-highlight-index-js" */),
  "component---src-pages-index-content-mdx": () => import("./../../../src/pages/index-content.mdx" /* webpackChunkName: "component---src-pages-index-content-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-pages-bio-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/neboat/Software/personal-website/gatsby-personal-site-v0/src/pages/bio.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-pages-bio-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-pages-contact-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/neboat/Software/personal-website/gatsby-personal-site-v0/src/pages/contact.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-pages-contact-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-pages-index-content-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/neboat/Software/personal-website/gatsby-personal-site-v0/src/pages/index-content.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-pages-index-content-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-pages-projects-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/neboat/Software/personal-website/gatsby-personal-site-v0/src/pages/projects.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-pages-projects-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-pages-teaching-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/Users/neboat/Software/personal-website/gatsby-personal-site-v0/src/pages/teaching.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-pages-teaching-mdx" */),
  "component---src-pages-papers-index-js": () => import("./../../../src/pages/papers/index.js" /* webpackChunkName: "component---src-pages-papers-index-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

